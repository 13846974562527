@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* base styles */
html {
  overflow-y: scroll;
}

*{
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #AA0000;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #AA0000;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.content {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}
.logo{
  max-height: 60px;
}


/* blog previews / list */
.blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.blog-preview h2 {
  font-size: 20px;
  color: #AA0000;
  margin-bottom: 8px;
}


.slider-image {
  display: block;
  object-fit: cover;
  margin: auto; /* This centers the image horizontally */
  height: 450px; /* Maintain aspect ratio */
  width: 100%;
  max-width: 100%; /* Prevent image from exceeding the container's width */
}


/* PopUpMessage.css */
.popup-message {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #fff;
  font-family: Arial, sans-serif;
}

.popup-message.success {
  background-color: #8dc18f; /* Green */
}

.popup-message.failure {
  background-color: #d87f79; /* Red */
}

.popup-message strong {
  display: block;
  margin-bottom: 10px;
}